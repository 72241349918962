export default [
  {
    label: 'Calender',
    identifier: 'datePicker',
    filters: [
      {
        label: 'Today',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'today',
      },
      {
        label: 'yesterday',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'yesterday',
      },
      {
        label: 'Last week',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'last_week',
      },
      {
        label: 'Custom Date',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'custom_date',
      },
    ],
  },
  {
    label: 'ambulance type',
    identifier: 'ambulanceType',
    filters: [
      {
        label: 'ALS',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'ALS',
      },
      {
        label: 'BLS',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'BLS',
      },
      // {
      //   label: 'Neonatal',
      //   icon: 'ph:clock-counter-clockwise-bold',
      //   identifier: 'neonatal',
      // },
      // {
      //   label: 'Moto',
      //   icon: 'ph:clock-counter-clockwise-bold',
      //   identifier: 'moto',
      // },
    ],
  },
  {
    label: 'case status',
    identifier: 'orderStatus',
    filters: [
      {
        label: 'Fulfilled',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'fulfilled',
      },
      {
        label: 'Submitted',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'submitted',
      },
      {
        label: 'Created',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'draft',
      },
      {
        label: 'On Trip',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'dispatched',
      },
      {
        label: 'Payment',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'payment',
      },
      {
        label: 'Scheduled',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'scheduled',
      },
      {
        label: 'Cancelled',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'cancelled',
      },
    ],
  },
  {
    label: 'Assignment Type',
    identifier: 'assignmentType',
    filters: [
      {
        label: 'Auto',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'AUTO',
      },
      {
        label: 'Manual',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'MANUAL',
      },
    ],
  },
  {
    label: 'Order Classification',
    identifier: 'orderClassification',
    filters: [
      {
        label: 'Inbound',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'inbound',
      },
      {
        label: 'Outbound',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'outbound',
      },
      {
        label: 'Lama/Dama',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'lama-dama',
      },
      {
        label: 'Transfer',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'transfer',
      },
    ],
  },
  {
    label: 'Payment Type',
    identifier: 'paymentType',
    filters: [
      {
        label: 'Cash',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'cash',
      },
      {
        label: 'Online',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'online',
      },
    ],
  },
  {
    label: 'vehicle ownership',
    identifier: 'vehicleOwnership',
    filters: [
      {
        label: 'Own',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'own',
      },
      {
        label: 'Leased',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'leased',
      },
      {
        label: 'Aggregator',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'aggregator',
      },
      {
        label: 'Franchised',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'franchised',
      },
    ],
  },
  {
    label: 'booking type',
    identifier: 'bookingType',
    filters: [
      {
        label: 'Enquiry',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'ENQUIRY',
      },
      {
        label: 'Lead',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'LEAD',
      },
      {
        label: 'Booking',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'BOOKING',
      },
    ],
  },
  {
    label: 'case type',
    identifier: 'orderType',
    filters: [
      {
        label: 'Non Critical',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'NON-CRITICAl',
      },
      {
        label: 'Critical',
        icon: 'ph:clock-counter-clockwise-bold',
        identifier: 'CRITICAL',
      },
      // {
      //   label: 'Scheduled',
      //   icon: 'ph:clock-counter-clockwise-bold',
      //   identifier: 'scheduled',
      // },
      // {
      //   label: 'Reserved',
      //   icon: 'ph:clock-counter-clockwise-bold',
      //   identifier: 'reserved',
      // },
    ],
  },
  {
    label: 'sort by',
    identifier: 'sortBy',
    filters: [
      {
        label: 'Booking Type',
        icon: '',
        identifier: 'bookingType',
      },
      // {
      //   label: 'Price: Low to High',
      //   icon: 'ph:clock-counter-clockwise-bold',
      //   identifier: 'Price: Low to High',
      // },
      // {
      //   label: 'Price: High to Low',
      //   icon: 'ph:clock-counter-clockwise-bold',
      //   identifier: 'Price: High to Low',
      // },
      // {
      //   label: 'ETA',
      //   icon: 'ph:clock-counter-clockwise-bold',
      //   identifier: 'ETA',
      // },
      // {
      //   label: 'Distance',
      //   icon: 'ph:clock-counter-clockwise-bold',
      //   identifier: 'Distance',
      // },
      // {
      //   label: 'Long Trips',
      //   icon: 'ph:clock-counter-clockwise-bold',
      //   identifier: 'Long Trips',
      // },
      // {
      //   label: 'Long Trips',
      //   icon: 'ph:clock-counter-clockwise-bold',
      //   identifier: 'Long Trips',
      // },
      // {
      //   label: 'Trip time',
      //   icon: 'ph:clock-counter-clockwise-bold',
      //   identifier: 'Trip time',
      // },
    ],
  },
]
